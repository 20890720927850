import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Seo from "../components/Seo"
import { Layout } from "../components/Layout"


import imgSealing from "../images/top-sealing-caption.svg"
import imgWaterproof from "../images/top-watarproof-caption.svg"

const WorksPage: React.FC = () => {
  const pageName: string = "業務案内";

  return (
    <Layout pageName={pageName}>
      <Seo title={`${pageName} | メープル・シーリング`} />
      <Container>
        <section>
          <Row>
            <Col xs={12}>
              <h2 className="subtitle">当社の業務のご案内</h2>
              <p>
                当社では、新築のシーリング工事全般・シーリング打ち換え工事・シーリングによる外壁補修工事や雨漏れ補修を承っております。また、ウレタン防水・シート防水によるベランダやバルコニーの防水工事を行っております。長年の経験を活かし、雨漏りの原因を突き止め、お客様の大切な資産を雨から守ります！お客様のご希望や建物に合わせた、丁寧な施工を心がけております。
              </p>
            </Col>
            <Col md={6} className="wrapper mt-4">
              <div className="top-panel-head">
                <h3>
                  <img
                    src={imgSealing}
                    alt="シーリング工事。建物の素材または形状に適した防水施工により雨から守ります"
                  />
                </h3>
                <div>
                  <AnchorLink
                    href="#sealing"
                    className="top-panel-btn"
                    aria-label="詳しく見る"
                  >
                    詳しく見る
                  </AnchorLink>
                </div>
              </div>
              <div className="top-panel-item">
                <StaticImage
                  src="../images/top-sealing.jpg"
                  alt="シーリング工事風景"
                  className="img-fit"
                />
              </div>
            </Col>
            <Col md={6} className="wrapper mt-4">
              <div className="top-panel-head">
                <h3>
                  <img
                    src={imgWaterproof}
                    alt="各種防水工事。建物の素材または形状に適した防水施工により雨から守ります"
                  />
                </h3>
                <div>
                  <AnchorLink
                    href="#waterproof"
                    className="top-panel-btn"
                    aria-label="詳しく見る"
                  >
                    詳しく見る
                  </AnchorLink>
                </div>
              </div>
              <div className="top-panel-item">
                <StaticImage
                  src="../images/top-watarproof.jpg"
                  alt="防水工事風景"
                  className="img-fit"
                />
              </div>
            </Col>
          </Row>
        </section>
        <section id="sealing">
          <Row>
            <Col sm={12}>
              <h2 className="subtitle">シーリング工事</h2>
            </Col>
            <Col lg={6}>
              <p>
                建物を雨水や空気の侵入から守るために、外壁の隙間や目地、サッシの周囲などをシーリング（またはコーキング）と呼ばれるゴム系の樹脂材で充填する工法です。建物内部では、キッチンやお風呂場といった水廻りの防水のためにシーリングが用いられています。
                建物には様々な素材が使われているため、外気温の変化や建物の振動により圧力がかかると、ひび割れや欠損が起きやすくなります。それを伸縮性によって防ぐのもシーリングの大切な役割です。
              </p>
            </Col>
            <Col lg={6} className="mt-1">
              <Row>
                <Col xs={6}>
                  <StaticImage
                    layout="fullWidth"
                    src="../images/works-sealing-before.png"
                    alt="シーリング施工前"
                    className="img-flame-b"
                  />
                  <p className="works-explanatory">施工前</p>
                </Col>
                <div className="works-arrow">
                  <StaticImage
                    src="../images/works-arrow.png"
                    alt="Before＆After"
                    width={100}
                  />
                </div>
                <Col xs={6}>
                  <StaticImage
                    layout="fullWidth"
                    src="../images/works-sealing-after.png"
                    alt="シーリング施工後"
                    className="img-flame-b"
                  />
                  <p className="works-explanatory">施工後</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col sm={12}>
              <h3 className="subtitle-s">外壁シーリング</h3>
            </Col>
            <Col lg={6}>
              <p>
                外壁の目地、サッシの隙間などにシーリング材を充填します。また、外壁に生じたひび割れをふさぎ、漏水を防ぎます。伸縮を繰り返し、紫外線や雨風にさらされたシーリング材は経年劣化していきます。シーリング材の劣化が進むと、シーリング材本来の防水効果を発揮できなくなります。施工後10年がシーリング打ち換えの目安です。
              </p>
            </Col>
            <Col lg={6} className="mt-1">
              <Row>
                <Col xs={6} className="text-center">
                  <StaticImage
                    src="../images/works-sealing-siding.png"
                    alt="サイディング目地"
                    className="img-flame-bp"
                  />
                  <p className="works-caption">サイディング目地</p>
                </Col>
                <Col xs={6} className="text-center">
                  {" "}
                  <StaticImage
                    src="../images/works-sealing-roof.png"
                    alt="屋上部雨漏り補修"
                    className="img-flame-bp"
                  />
                  <p className="works-caption">屋上部雨漏り補修</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col sm={12}>
              <h3 className="subtitle-s">室内シーリング</h3>
            </Col>
            <Col lg={6}>
              <p>
                キッチン・洗面台のシンク廻り、お風呂場の防水のためにシーリング施工をします。水廻りのコーキングは、毎日水にさらされるため、剥がれやカビが発生しやすい傾向があります。ご家庭での清掃によってシーリングについたカビをきれいにできる場合もありますが、個人での対応が難しい場合はご相談ください。シーリングを新たに打ち換えることによって清潔な空間を提供いたします。
              </p>
            </Col>
            <Col lg={6} className="mt-1">
              <Row>
                <Col xs={6} className="text-center">
                  <StaticImage
                    src="../images/works-sealing-washbasin.png"
                    alt="洗面台廻り"
                    className="img-flame-bp"
                  />
                  <p className="works-caption">洗面台廻り</p>
                </Col>
                <Col xs={6} className="text-center">
                  <StaticImage
                    src="../images/works-sealing-bathroom.png"
                    alt="バスタブ廻り"
                    className="img-flame-bp"
                  />
                  <p className="works-caption">バスタブ廻り</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col sm={12}>
              <h3 className="subtitle-s">外壁クラック補修</h3>
            </Col>
            <Col lg={6}>
              <p>
                老朽化により外壁に生じたクラック(ひび割れ)にシーリング材を充填し、雨漏りを防ぎます。シーリング材に十分な厚みを持たせて防水性を高めるために、シーリング材充填前に、グラインダーを使いクラックをV字にカットし目地を形成します。老朽化した集合住宅や工場など、主に美観よりも防水性が求められる建物の補修工事や、塗装塗り替え前の補修工事に用いられる工法です。
              </p>
            </Col>
            <Col lg={6} className="mt-1">
              <Row>
                <Col xs={6} className="text-center">
                  <StaticImage
                    src="../images/works-sealing-vcut.png"
                    alt="クラックVカット"
                    className="img-flame-bp"
                  />
                  <p className="works-caption">クラックVカット</p>
                </Col>
                <Col xs={6} className="text-center">
                  <StaticImage
                    src="../images/works-sealing-vcutseal.png"
                    alt="シーリング材充填"
                    className="img-flame-bp"
                  />
                  <p className="works-caption">シーリング材充填</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col sm={12}>
              <h3 className="subtitle-s">シーリング工事の流れ</h3>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">
                １．古いシーリング材撤去
                <br className="d-none d-md-block" />
                　　※打ち換えの場合
              </h4>
            </Col>
            <Col md={8} lg={9}>
              <p>カッターを使い、既存のシーリング材を丁寧に撤去します。</p>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">２．ケレン・清掃</h4>
            </Col>
            <Col md={8} lg={9}>
              <p>
                新しいシーリング材をしっかり接着させるために、施工箇所に付着したバリなどを落とし清掃します。
              </p>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">３．養生テープ貼り</h4>
            </Col>
            <Col md={8} lg={9}>
              <p>
                余分なシーリング材が施工箇所外部を汚さないように、マスキングテープで養生をします。
              </p>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">４．バックアップ材挿入</h4>
            </Col>
            <Col md={8} lg={9}>
              <p>
                シーリング材を適切な厚みにするためにバックアップ材を挿入します。伸縮性・耐久性にかかわるので、シーリング材に十分な厚みが取れるように配慮します。サイディング・金属パネル目地などのワーキングジョイントの場合、二点接着（追従性を保つため目地底面を接着させない）にするために、ボンドブレーカーを目地底面に貼っていきます。
              </p>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">５．プライマー塗布</h4>
            </Col>
            <Col md={8} lg={9}>
              <p>
                プライマーと呼ばれる接着剤をムラなく施工箇所に塗布します。プライマーの塗布が十分でないと、シーリング材の接着力が落ちてしまいます。シーリングの耐久年数・防水性に関わる大切な工程です。
              </p>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">６．シーリング打ち</h4>
            </Col>
            <Col md={8} lg={9}>
              <p>
                コーキング（シーリング）ガンを使って材料を施工箇所に充填していきます。この工程でシーリング材の裏に空気が入ると、その部分だけシーリング材が薄くなってしまうため、空気が入らないように充填していきます。
              </p>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">７．へら押え</h4>
            </Col>
            <Col md={8} lg={9}>
              <p>
                充填されたコーキング（シーリング）材をヘラを使って平滑にならしていきます。この時にしっかりと圧をかけて目地にシーリング材が密着するようにします。美観にも関係する仕上げの工程です。
              </p>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">８．養生はがし</h4>
            </Col>
            <Col md={8} lg={9}>
              <p>最後にマスキングテープをはがして施工完了です。</p>
            </Col>
          </Row>
        </section>
        <section id="waterproof">
          <Row>
            <Col sm={12}>
              <h2 className="subtitle">各種防水工事</h2>
            </Col>
            <Col lg={6}>
              <p>
                バルコニーやベランダ、屋上などの雨にさらされる平面では、防水性塗膜や防水シートによる防水工事が適しています。防水層は約10年で劣化していきます。環境によって差はありますが、10年～15年に1度を目安に防水改修工事をするのが望ましいといえます。防水工事にはアスファルト防水・FRP防水など様々な工法がありますが、当社では、ウレタン防水とシート防水による防水工事を承っております。
              </p>
            </Col>
            <Col lg={6} className="mt-1">
              <Row>
                <Col xs={6}>
                  <StaticImage
                    layout="fullWidth"
                    src="../images/works-waterproof-before.jpg"
                    alt="防水施工前"
                    className="img-flame-b"
                  />
                  <p className="works-explanatory">施工前</p>
                </Col>
                <div className="works-arrow">
                  <StaticImage
                    src="../images/works-arrow.png"
                    alt="Before＆After"
                  />
                </div>
                <Col xs={6}>
                  <StaticImage
                    layout="fullWidth"
                    src="../images/works-waterproof-after.jpg"
                    alt="防水施工後"
                    className="img-flame-b"
                  />
                  <p className="works-explanatory">施工後</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="row mt-5">
            <Col sm={12}>
              <h3 className="subtitle-s">ウレタン防水</h3>
            </Col>
            <Col lg={6}>
              <p>
                液状のウレタン樹脂を塗布し、ゴム質の防水膜を形成する工法です。作業時は液状のため複雑な形状でも比較的簡単に施工でき、シート防水と比べつなぎ目もないので美観にも優れる工法です。改修工事の際、旧防水層の撤去の必要がなく塗り重ねが可能なため、多くの改修工事で用いられています。約5年ごとにトップコートを再塗装することにより、紫外線による防水膜の劣化を防ぎ、約15年防水効果を維持できます。
              </p>
            </Col>
            <Col lg={6} className="mt-1">
              <Row>
                <Col xs={6} className="text-center">
                  <StaticImage
                    src="../images/works-waterproof-urethane1.jpg"
                    alt="プライマー塗布"
                    className="img-flame-bp"
                  />
                  <p className="works-caption">プライマー塗布</p>
                </Col>
                <Col xs={6} className="text-center">
                  <StaticImage
                    src="../images/works-waterproof-urethane2.jpg"
                    alt="ウレタン樹脂塗布"
                    className="img-flame-bp"
                  />
                  <p className="works-caption">ウレタン樹脂塗布</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="row mt-5">
            <Col sm={12}>
              <h3 className="subtitle-s">シート防水</h3>
            </Col>
            <div className="col-lg-6 text-center">
              <p>
                塩化ビニールまたはゴム素材のシートを使用する工法です。耐摩耗性、伸縮性に富み、建物の収縮に追従する優れた工法です。
              </p>
            </div>
            <Col lg={6} className="mt-1">
              <Row>
                <Col xs={6} className="text-center">
                  <StaticImage
                    src="../images/works-waterproof-seat-work.png"
                    alt="施工"
                    className="img-flame-bp"
                  />
                  <p className="works-caption">施工</p>
                </Col>
                <Col xs={6} className="text-center">
                  <StaticImage
                    src="../images/works-waterproof-seat-finish.png"
                    alt="はっ水性抜群！"
                    className="img-flame-bp"
                  />
                  <p className="works-caption">はっ水性抜群！</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col sm={12}>
              <h3 className="subtitle-s">
                防水工事（ウレタン防水密着工法）の流れ
              </h3>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">１．ケレン・清掃</h4>
            </Col>
            <Col md={8} lg={9}>
              <p>
                防水材をしっかり接着させるために、施工箇所に付着したゴミ・ホコリなどを落とし清掃します。
              </p>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">２．養生</h4>
            </Col>
            <Col md={8} lg={9}>
              <p>
                余分な防水材が施工箇所外部を汚さないように養生をします。ウレタン防水の場合材料が液状なので、排水口廻りも養生をし、ドレンパイプ内にまで防水材が流れていかないようにします。
              </p>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">３．プライマー塗布</h4>
            </Col>
            <Col md={8} lg={9}>
              <p>
                プライマーと呼ばれる接着剤をムラなく施工箇所に塗布します。下地との密着性を高める大切な工程です。
              </p>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">４．既存下地補修</h4>
            </Col>
            <Col md={8} lg={9}>
              <p>
                既存の下地に、ひび割れ、既存の防水層の剥がれなどが発生している場合は、シーリング材等を使って防水材塗布前に補修を行います。
              </p>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">５．メッシュ貼り</h4>
            </Col>
            <Col md={8} lg={9}>
              <p>
                防水塗膜に十分な厚さ、強度を持たせるためにガラス繊維のメッシュを貼ります。
              </p>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">６．ウレタン樹脂塗布1回目</h4>
            </Col>
            <Col md={8} lg={9}>
              <p>
                ローラーやコテを使ってウレタン樹脂防水材を塗布していきます。
              </p>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">７．ウレタン樹脂塗布２回目</h4>
            </Col>
            <Col md={8} lg={9}>
              <p>
                防水膜が十分な防水性能・耐久性を発揮できるように、ウレタン樹脂防水材を既定の厚みに再度塗布していきます。
              </p>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">８．トップコート塗布</h4>
            </Col>
            <Col md={8} lg={9}>
              <p>
                紫外線によるウレタン防水材の劣化を防ぐために、乾燥後にトップコート（保護用の塗料）を塗布します。
              </p>
            </Col>
          </Row>
          <Row className="works-flow">
            <Col md={4} lg={3}>
              <h4 className="works-flow-title">９．養生はがし</h4>
            </Col>
            <Col md={8} lg={9}>
              <p>最後に施工箇所の養生をはがして完了です。</p>
            </Col>
          </Row>
        </section>
      </Container>
    </Layout>
  )
}

export default WorksPage
